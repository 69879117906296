<template>
  <section id="Gallery">
    <h2>Gallery</h2>
    <video playsinline muted loop autoplay preload="auto" poster="/resource/video/hot.mov">
      <source src="/resource/video/store2_main.mov" type="video/mp4">
    </video>
    <div
        id="show-modal"
        class="button more"
        @click="onClickShowModal"
    >more..
    </div>
    <Modal v-if="showModal" @close="onClickShowModal">
      <h3 slot="header">
        <video playsinline muted loop autoplay preload="auto" poster="/resource/video/hot.mov">
          <source src="/resource/video/hot.mov" type="video/mp4">
        </video>
      </h3>
      <div slot="body" class="grid-container">
        <div
            class="grid"
            v-for="(imageUrl, key) in imageUrlList"
            :key="key"
        >
          <img v-if="!imageUrl.endsWith('.mov')" :src="imageUrl" alt="Kakuya Coffee Stand - Gallery">
          <video v-else playsinline muted loop autoplay preload="auto" :poster="imageUrl">
            <source :src="imageUrl" type="video/mp4">
          </video>
        </div>
      </div>
    </Modal>
  </section>
</template>

<script>
import Modal from "@/common/Modal";

export default {
  name: "Gallery",
  components: {
    Modal
  },
  methods: {
    onClickShowModal: function () {
      this.showModal = !this.showModal;
      const body = document.body;
      if (body.getAttribute('style') === null || body.getAttribute('style').length <= 0) {
        body.style.overflow = 'hidden';
      } else {
        body.removeAttribute('style');
      }
    }
  },
  data() {
    return {
      showModal: false,
      onFocus: false,
      imageUrlList: [
        "/resource/img/gallery/kakuya.jpg",
        "/resource/img/gallery/dripper.jpg",
        "/resource/img/gallery/dripping.jpg",
        "/resource/img/gallery/hot.jpg",
        "/resource/video/ice.mov",
        "/resource/img/gallery/overview.jpg",
        "/resource/img/gallery/press.jpg",
        "/resource/img/gallery/sofa.jpg",
        "/resource/img/gallery/d3e9b83066cdfa9c10dedc9b90c384a5.jpg",
        "/resource/img/gallery/machine.jpg",
        "/resource/video/roast.mov",
        "/resource/img/gallery/cannes_kakuyacoffeestand.png",
        "/resource/img/gallery/cups.jpg",
        "/resource/img/gallery/cup1.jpg",
        "/resource/img/gallery/cup2.jpg",
        "/resource/video/store2_daily.mov",
        "/resource/img/gallery/face_cups.jpg",
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

section {
  position: relative;

  video {
    width: 100%;
  }

  .more {
    margin: 2rem 0 2rem 0;
    width: 12.8rem;
    height: 4rem;
    line-height: 4rem;
  }
}

@media screen and (min-width: 768px) {
  section {
    width: 90%;
    margin: 0 auto;
    padding: 10rem 0 4rem 0;

    .grid-container {
      margin: 0 auto;
      width: 100%;
      column-gap: 2rem;
      column-count: 2;

      h3 {
        margin: 2rem 0 2rem 0;
      }

      .grid {
        padding: 1rem;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;

        img {
          width: 100%;
        }

        video {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  $viewWidth: 100%;
  section {
    width: 100%;
    padding: 1.6rem 0 8rem 0;

    .grid-container {
      margin: 0 auto;
      width: 100%;
      column-gap: 0;
      column-count: 1;

      h3 {
        margin: 2rem 0 2rem 0;
      }

      .grid {
        padding: 3px;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;

        img {
          width: 100%;
        }

        video {
          width: 100%;
        }
      }
    }
  }
}
</style>