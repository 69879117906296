<template>
  <section id="Access">
    <h2>Access</h2>
    <h3>本店</h3>
    <GmapMap
        :center="first_position"
        :zoom="17"
        map-type-id="roadmap"
        :options="map_option"
        class="ggmap"
    >
      <GmapMarker
          :position="first_position"
          :clickable="false"
          :draggable="false"
      />
    </GmapMap>
    <dl>
      <dt>住所</dt>
      <dd>〒273-0031 千葉県船橋市西船4-24-8</dd>
      <dt>アクセス</dt>
      <dd>西船橋駅(北口徒歩1分)</dd>
      <dt>営業時間</dt>
      <dd>一時休業中</dd>
<!--      <dt>定休日</dt>-->
<!--      <dd>年中無休(不定休)</dd>-->
    </dl>
    <h3>2号店</h3>
    <GmapMap
        :center="second_position"
        :zoom="15"
        map-type-id="roadmap"
        :options="map_option"
        class="ggmap"
    >
      <GmapMarker
          :position="second_position"
          :clickable="false"
          :draggable="false"
      />
    </GmapMap>
    <dl>
      <dt>住所</dt>
      <dd>〒273-0031 千葉県船橋市西船5-15-5 2F</dd>
      <dt>アクセス</dt>
      <dd>西船橋駅(北口徒歩10分)</dd>
      <dt>営業時間</dt>
      <dd>12:00〜22:00</dd>
      <dt>定休日</dt>
      <dd>年中無休(不定休)</dd>
    </dl>
  </section>
</template>

<script>
export default {
  name: "Map",
  data() {
    return {
      first_position: {
        lat: 35.70868,
        lng: 139.959191
      },
      second_position: {
        lat: 35.711782,
        lng: 139.954374
      },
      map_option: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$viewWidth: 100%;

p {
  text-align: center;
}

@media screen and (min-width: 768px) {
  section {
    width: 90%;
    text-align: left;
    padding: 10rem 0 4rem 0;
    margin: 0 auto;

    dl {
      margin: 0 auto;
      padding: 1.6rem;

      dt {
        float: left;
        clear: left;
        font-size: 1.6rem;
        line-height: 3.2rem;
        width: 10rem;
        text-align: left;
      }

      dd {
        font-size: 1.6rem;
        line-height: 3.2rem;
        text-align: left;
      }
    }

    .ggmap {
      position: relative;
      /*padding-bottom: 50%;*/
      /*padding-top: 30px;*/
      height: 50vh;
      overflow: hidden;
    }

    .ggmap iframe,
    .ggmap object,
    .ggmap embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 400px;
    }
  }
}

@media screen and (max-width: 767px) {
  section {
    width: $viewWidth;
    padding: 1.6rem 0 1.6rem 0;
  }

  dl {
    margin: 0 auto;
    padding: 1.6rem;
  }

  dt {
    float: left;
    clear: left;
    font-size: 1.3rem;
    line-height: 3.2rem;
    width: 8rem;
    text-align: left;
  }

  dd {
    font-size: 1.3rem;
    line-height: 3.2rem;
    text-align: left;
  }

  .ggmap {
    position: relative;
    /*padding-bottom: 50%;*/
    /*padding-top: 30px;*/
    height: 40vh;
    overflow: hidden;
  }

  .ggmap iframe,
  .ggmap object,
  .ggmap embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
  }
}
</style>