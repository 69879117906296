<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click.self="$emit('close')">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <div class="modal-default-button close" @click="$emit('close')">
                close
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal"
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  overflow: hidden;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .7);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  position: relative;
  height: 100vh;
}

.modal-container {
  width: 90%;
  margin: 0 auto;
  border-radius: 2px;
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: auto;
  max-height: 85vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-header h3 {
  margin: 0 0 0 0;
  padding: 1.6rem 0 1.6rem 0;
}

.modal-body {
  margin: 0 auto;
}

.modal-footer {
  position: relative;
  margin: 1.6rem 0 1.6rem 0;
  padding: 1.6rem 0 1.6rem 0;
}

.modal-default-button {
  position: absolute;
  right: 0;
  top: 0;

  line-height: 3.2rem;
  text-align: center;
  border-radius: 1.6rem;
  font-weight: bold;
  background: #888;
  color: #fff;
}

.close {
  width: 11.2rem;
  height: 3.2rem;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
</style>
