<template>
  <main id="app">
    <router-view class="view"></router-view>
  </main>
</template>

<script>
import 'normalize.css'

export default {
  name: 'App',
  serverCacheKey: false,
  data() {
    return {
      defaultTitle: 'Kakuya Coffee Stand',
      defaultDescription: 'すれちがうだけだった人と人が”珈琲”というツールで出会う。Kakuya Coffee Standは珈琲屋台で全国を周ることから始まった。'
    }
  },
  methods: {
    createPageTitle: function (to) {
      // タイトルを設定
      document.title = this.defaultTitle;
      if (to.meta.title) {
        document.title = to.meta.title + ' | ' + this.defaultTitle;
      }

      // メタタグdescription設定
      if (to.meta.description) {
        document.querySelector("meta[name='description']").setAttribute('content', to.meta.description + ' | ' + this.defaultDescription);
      } else {
        document.querySelector("meta[name='description']").setAttribute('content', this.defaultDescription);
      }
    }
  },
  mounted: function () {
    this.createPageTitle(this.$route);
  },
  watch: {
    '$route'(to) {
      this.createPageTitle(to);
    }
  }
}
</script>

<style>
html {
  min-width: 320px;

  width: 100vw;
  height: 100vh;

  font-family: "Hiragino Kaku Gothic Pro", sans-serif;
  font-size: 62.5%;
}

body {
  /*max-width: 768px;*/
  width: 100%;
  margin: 0 auto;
}

@font-face {
  font-family: 'honoka-min';
  src: url('/resource/font/font_1_honokamin.ttf') format('truetype');
}

@font-face {
  font-family: 'Zapfino';
  font-style: normal;
  font-weight: normal;
  src: local('Zapfino'), url('/resource/font/Zapfino.woff') format('woff');
}

#app {
  font-family: 'Hannari', 'Zapfino', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fadein-load-enter-active {
  transition: opacity 1.5s ease;
}

.fadein-load-leave-active {
  transition: opacity 1s ease;
}

.fadein-load-enter,
.fadein-load-leave-to {
  opacity: 0;
}

h2, h3 {
  font-family: 'Zapfino', sans-serif;
  text-align: center;
}

h2 {
  font-size: 2rem;
  padding: 1.5rem 0 1.5rem 0;
}

h3 {
  font-size: 1.5rem;
  margin: 3rem 0 2rem 0;
}

.button {
  position: absolute;
  right: 1rem;

  line-height: 2rem;
  text-align: center;
  border-radius: 1rem;

  font-family: 'Zapfino', sans-serif;
  font-size: 2rem;
  font-weight: bold;

  background: #888;
  color: #fff;
}
</style>
