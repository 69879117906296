<template>
    <div>
        <Header></Header>
        <MainVisual></MainVisual>
        <Concept></Concept>
        <Gallery></Gallery>
        <Map></Map>
        <Contact></Contact>
        <Footer></Footer>
    </div>
</template>

<script>
    import Map from "@/pages/detail/components/Map";
    import Header from "@/pages/detail/components/Header";
    import MainVisual from "@/pages/detail/components/MainVisual";
    import Contact from "@/pages/detail/components/Contact";
    import Concept from "@/pages/detail/components/Concept";
    import Gallery from "@/pages/detail/components/Gallery";
    import Footer from "@/pages/detail/components/Footer";

    export default {
        name: "Detail",
        components: {
            Footer,
            Concept,
            Contact,
            MainVisual,
            Header,
            Map,
            Gallery
        }
    }
</script>

<style scoped>
    * {
        font-family: 'honoka-min',sans-serif, 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
    }
</style>