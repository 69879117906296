<template>
  <transition name="fadein-load" appear v-on:after-enter="afterEnter" v-on:after-leave="afterLeave">
    <img v-show="isHidden" class="m-logo" alt="Kakuya Coffee Stand,カクヤコーヒースタンド" src="/resource/img/logo02.png">
  </transition>
</template>

<script>
import router from '@/router'

export default {
  name: "top",
  components: {},
  data: () => {
    return {
      isHidden: true
    };
  },
  methods: {
    afterEnter: function () {
      this.isHidden = false;
    },
    afterLeave: function () {
      router.push({
        name: 'detail'
      })
    }
  },
}
</script>

<style scoped>
img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

@media screen and (min-width: 768px) {
  img {
    width: 30%;
    height: auto;
  }
}

@media screen and (max-width: 767px) {
  img {
    width: 80%;
    height: auto;
  }
}
</style>