<template>
  <header
      v-bind:class="{ 'sticky': isSticky }"
      v-scroll="handleScroll">
    <router-link
        :to="{name: 'top'}"
        v-bind:class="{ 'sticky': isSticky }"
        v-scroll="handleScroll"
        class="logo"
    ></router-link>
    <a
        class="insta-icon"
        href="https://www.instagram.com/kakuya__coffee/?ref=badge"
        target="_blank"
        rel="noopener"
        v-bind:class="{ 'sticky': isSticky }"
        v-scroll="handleScroll"
        @click="instagram_link_onClicked"
    ></a>
  </header>
</template>

<script>
export default {
  name: "Header",
  data: () => {
    return {
      position: 0,
      isSticky: false
    }
  },
  methods: {
    instagram_link_onClicked: function () {
      this.$ga.event('Link', 'external', 'instagram', 1);
    },
    handleScroll: function () {
      this.position = document.documentElement.scrollTop || document.body.scrollTop;
      this.isSticky = this.position > 600;
    }
  }
}
</script>

<style scoped lang="scss">
header {
  position: fixed;
  transition: all 0.3s ease;
  width: 100%;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
  z-index: 10000;

  .logo {
    position: absolute;
    transition: all 0.3s ease;

    width: 50%;
    height: 10rem;
    z-index: 10000;

    background-image: url("/resource/img/logo_square.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .logo.sticky {
    width: 50%;
    height: 3.2rem;

    padding: 0 0 1rem 0;

    background-image: url("/resource/img/logo01.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .insta-icon {
    position: absolute;
    transition: all 0.3s ease;
    right: 0;
    width: 6rem;
  }

  .insta-icon::before {
    width: 5rem;
    height: 5rem;

    margin-right: 5rem;
    margin-bottom: -5rem;

    content: '';
    display: inline-block;
    background-image: url('/resource/img/sns/instagramm/color.png');
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .insta-icon.sticky {
    width: 5rem;
    height: 0;
  }

  .insta-icon.sticky::before {
    margin: 0;
    width: 4rem;
    height: 4rem;
    content: '';
    display: inline-block;
    background-image: url('/resource/img/sns/instagramm/color.png');
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

header.sticky {
  height: 6rem;
  z-index: 10000;
  padding: 1rem;
  background-color: black;
}

@media screen and (min-width: 768px) {
}

@media screen and (max-width: 767px) {
}

</style>