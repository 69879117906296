<template>
  <div>
    <h3 v-if="title">{{ title }}</h3>
    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "Paragraph",
  props: {
    title: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
div {
  display: block;
  margin: 1.6rem;
}

h3 {
  margin: 0 auto;
  padding: 1.6rem;

  background-color: rgb(188, 156, 120);
  color: white;

  font-size: 2.4rem;
}

p {
  margin: 0 auto;
  white-space: pre-line;
  font-size: 1.6rem;
  line-height: 3.2rem;
}
</style>