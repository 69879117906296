<template>
  <section>
    <img class="logo" alt="Kakuya Coffee Stand,カクヤコーヒースタンド" src="/resource/img/logo_apa_white_small.png">
    <picture>
      <source
          media="(min-width: 768px)"
          srcset="/resource/img/detail/main-pc.png?1906022338">
      <source
          media="(max-width: 767px)"
          srcset="/resource/img/detail/main-min.png?1906022338">
      <img class="main" src="/resource/img/detail/main-min.png?1906022338" alt="すれちがうだけだった人と人が”珈琲”というツールで出会う。">
    </picture>
    <h1 class="prologue" v-html="prologueMessage"></h1>
    <p class="beginning" v-html="beginMessage"></p>
  </section>
</template>

<script>
export default {
  name: "MainVisual",
  data() {
    return {
      prologueMessage: 'すれちがうだけだった人と人が<br/>' +
          '”珈琲”というツールで出会う。',
      beginMessage: 'Kakuya Coffee Standは<br/>' +
          '珈琲屋台で全国を周ることから始まった'
    }
  }
}
</script>

<style scoped>
section {
  width: 100%;
  text-align: center;
  position: relative;
}

.main {
  background: #000;
  width: 100%;
  height: auto;
  position: relative;
  z-index: -10000;
}

.prologue {
  position: absolute;
  color: white;
  font-weight: bold;
  top: 22%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.beginning {
  background: #000;
  color: white;
  margin: -3px 0 0 0;
  padding: 3.2rem 1.6rem 3.2rem 1.6rem;
  font-size: 1.6rem;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .logo {
    position: absolute;
    width: 22%;
    left: 39%;
    top: 30%;
  }

  .prologue {
    font-size: 3.2rem;
    top: 18%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}

@media screen and (max-width: 767px) {
  .logo {
    position: absolute;
    width: 50%;
    left: 25%;
    top: 39%;
  }

  .prologue {
    font-size: 2.4rem;
    top: 22%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
</style>