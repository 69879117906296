import Vue from 'vue'
import router from '@/router'
import App from "@/App";
import * as VueGoogleMaps from 'vue2-google-maps'
import VueAnalytics from 'vue-analytics';
import SocialSharing from 'vue-social-sharing';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

Vue.config.productionTip = false;

library.add(fas, fab);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.directive('scroll', {
    inserted: function (el, binding) {
        let f = function (evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f)
            }
        };
        window.addEventListener('scroll', f)
    }
});

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDgaZulRaVRLRhCycUWT2lq3E7Q0Wi17os',
        v: '3.37'
    }
});

Vue.use(VueAnalytics, {
    id: 'UA-140850468-1',
    checkDuplicatedScript: true,
    router
});

Vue.use(SocialSharing);

new Vue({
    el: '#app',
    router,
    render: h => h(App)
});