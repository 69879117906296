<template>
  <footer>

    <div class="copyrights">
      <p>© 2019 Kakuya Coffee Stand</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => {
    return {}
  }
}
</script>

<style scoped>
footer {
  padding: 20px 10px 10px 10px;
  background-color: gray;
  color: #fff;
  text-align: center;
}

p {
  font-weight: bold;
  font-family: sans-serif;
  font-size: 4pt;
}
</style>