import Vue from 'vue'
import Router from 'vue-router'

import NotFoundComponent from '@/pages/error/not-found'
import Top from '@/pages/top/index'
import Detail from '@/pages/detail/index'

Vue.use(Router);

const routes = [
    {
        path: '*',
        component: NotFoundComponent
    },
    {
        path: '/',
        name: 'top',
        component: Top,
        meta: {
            title: ''
        }
    },
    {
        path: '/detail/',
        name: 'detail',
        component: Detail
    }
];

const router = new Router({
    mode: 'history',
    routes: routes
});

export default router;