<template>
  <section id="Contact">
    <h2>Contact</h2>
    <ul class="m-contact-links">
      <li>
        <a
            href="https://www.instagram.com/kakuya__coffee/"
            target="_blank"
            @click="snsOnClicked('Instagram')"
        >
          <font-awesome-icon :icon="['fab', 'instagram']"/>
        </a>
      </li>
      <li>
        <a
            href="https://twitter.com/kakuya_coffee2"
            target="_blank"
            @click="snsOnClicked('Twitter')"
        >
          <font-awesome-icon :icon="['fab', 'twitter']"/>
        </a>
      </li>
      <li>
        <a
            href="mailto:kakuyacoffeestand@gmail.com?subject=%5BKakuya%20Coffee%20Stand%5D%20%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B"
            @click="mail_link_onClicked"
        >
          <font-awesome-icon :icon="['fas', 'envelope']"/>
        </a>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "Contact",
  methods: {
    mail_link_onClicked: function () {
      this.$ga.event('Link', 'mailto', 'kakuyacoffeestand@gmail.com', 1);
    },
    snsOnClicked: function (name) {
      this.$ga.event("Link", "sns", name, 1);
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  position: relative;
}
@media screen and (min-width: 768px) {
  section {
    width: 90%;
    margin: 0 auto;
    padding: 2rem 0 8rem 0;

    position: relative;
    font-size: 4rem;

    .m-contact-links {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      list-style: none;

      margin: 0 0 0 0;
      padding: 0 0 0 0;

      li {
        margin: 2.6rem 10rem;
        height: 3.2rem;

        a {
          color: #333333;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  section {
    width: 100%;
    padding: 1rem 0 3rem 0;

    position: relative;

    font-size: 2.9rem;

    .m-contact-links {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      list-style: none;

      margin: 0 0 0 0;
      padding: 0 0 0 0;

      li {
        margin: 2rem 3.8rem;
        height: 3rem;

        a {
          color: #333333;
        }
      }
    }
  }
}
</style>