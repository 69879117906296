<template>
  <section>
    <h2>Concept</h2>
    <div class="m-concept">
      <picture>
        <source
            media="(min-width: 768px)"
            srcset="/resource/img/introduce-yoko.png?1906030151">
        <source
            media="(max-width: 767px)"
            srcset="/resource/img/introduce-min.png?1906030151">
        <img src="/resource/img/introduce-min.png?1906030151" alt="カクヤコーヒースタンドのコンセプト"/>
      </picture>
      <div>
        <Paragraph
            v-for="sentence in sentences"
            v-bind:key="sentence.id"
            :title="sentence.title"
            v-bind:text="sentence.text"
        ></Paragraph>
      </div>
    </div>
  </section>
</template>

<script>
import Paragraph from "@/common/Paragraph";

export default {
  name: "Concept",
  components: {
    Paragraph
  },
  data: () => {
    return {
      sentences: [
        {
          id: 1,
          text: '多くの人とすれちがう毎日の中でも、関わる人は変わらず同じ。\n' +
              '人はつながりを失いつつあり\n' +
              'そんなさびしい時代に生きている。\n' +
              '（そんな気がする）\n'
        },
        {
          id: 2,
          text: '問題はつながりを失ったことにあるのではなく\n' +
              'その作り方を忘れてしまったことだ。\n' +
              '私が大切にしたいのは、挨拶から始まる自然なつながりである。\n'
        },
        {
          id: 3,
          text: 'おはよう、こんにちは、こんばんは、と\n' +
              '言葉を交わすこと。\n' +
              'それは共通の認識があってはじめて生まれるふるまいの一つである。\n' +
              '私はこのきっかけを大切にしたく、「いらっしゃいませ」とは言わない。\n' +
              '挨拶から始まる物語があると信じている。'
        }
      ]
    }
  }
}
</script>
<style scoped lang="scss">
@media screen and (min-width: 1240px) {
  section {
    width: 90%;
    text-align: left;
    padding: 10rem 0 4rem 0;
    margin: 0 auto;

    .m-concept {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      img {
        height: 70%;
      }
    }
  }
}

@media screen and (max-width: 1239px) {
  section {
    width: 100%;
    text-align: center;
    padding: 1.6rem 0 1.6rem 0;

    .m-concept {
      display: block;
      img {
        width: 100%;
     }
    }
  }
}
</style>
